import Carousel from 'react-multi-carousel';
import { Image } from "semantic-ui-react";
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};
const images = [
  "./slide1.jpg",
  "./slide2.jpg",
  "./slide3.jpg",
  "./slide4.jpg",
  "./slide5.jpg",
  "./slide6.jpg",
  "./slide7.jpg",
  "./slide8.jpg",
  "./slide9.jpg",
  "./slide10.jpg",
  "./slide11.jpg",
  "./slide12.jpg",
  "./slide13.jpg",
  "./slide14.jpg",
  "./slide15.jpg",
];

const footerNavigation = {
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/coppikoffie/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://instagram.com/coppikoffie',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/coppikoffie',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
}

export default function Layout() {
  return (
    <div className="bg-white pt-0 sm:mt-6 md:pt-12">
      <main>
        {/* Hero section */}
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src="./girona_header.jpg"
                  alt="COPPI in girona"
                />
                {/* <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" /> */}
              </div>
              <div className="relative px-6 py-24 sm:px-8 sm:py-32 lg:py-48 lg:px-12 ">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white text-shadow">COPPIRide Girona 2024</span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center text-shadow-md text-2xl text-white font-extrabold sm:max-w-3xl">
                <span className="line-through">26 september t/m 29 september</span> - UITVERKOCHT</p>
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5">
                    <a
                      href="#featured"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-yellow-500 bg-white hover:bg-yellow-50 sm:px-8"
                    >
                      Vertel me meer!
                    </a>
                    {/* <a
                      href="mailto:yoram@coppikoffie.nl?subject=Ja, vertel me meer over de Girona-reis!"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                    >
                      JA, Ik ga mee!
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative pt-16 pb-32 overflow-hidden" id="featured">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Wat is de COPPIRide Girona?
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                    De COPPIRide Girona is precies wat je denkt dat het is: een COPPIRide van 3 dagen in de hoofdstad van het moderne profwielrennen: Girona. Er wonen meer dan 100 profs in Girona, en dat is niet voor niets: de wegen zijn rustig, de automobilisten geduldig en beleefd, het eten is er heerlijk, de landschappen zijn schitterend.
                    </p>
                    <p className="mt-4 text-lg text-gray-500">
                    En wij hebben nog een voordeel: we hebben een lokale gids die uit Rotterdam komt en alle mooie routes en hippe koffietentjes kent!
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="./watiscoppigirona.jpg"
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Hoe werkt het?
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                    Je stapt in Rotterdam op het vliegtuig op donderdagmiddag, je komt in Girona aan, daar staat een fiets klaar die helemaal voor je is afgesteld, dan gaan we eten, fietsen, lullen, lachen, fietsen en eten, en zondagochtend vliegen we weer terug.
                    </p>
                  </div>
                </div>
                {/* <div className="mt-6">
                  <a
                    href="mailto:yoram@coppikoffie.nl?subject=Ja, vertel me meer over de Girona-reis!"
                    className="inline-flex bg-yellow-500 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                  >
                    Lekker, ik ga mee!
                  </a>
                </div> */}
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="./hoewerkthet.jpg"
                    alt="COPPI Girona"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Gradient Feature Section */}
        <div className="bg-gradient-to-r from-purple-800 to-indigo-700">
          <Carousel
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2500}
            partialVisbile
            itemClass="image-item"
            responsive={responsive}
          >
            {images.map(image => {
              return (
                <Image
                  draggable={false}
                  style={{ width: "100%", height: "100%" }}
                  src={image}
                />
              );
            })}
          </Carousel>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative pt-16 pb-32 overflow-hidden" id="featured">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Kost dat?
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                    Het kost rond de &euro;799. Daarvoor krijg je: 3 nachten onderdak in het prachtige Equipe guesthouse in Girona. Het is een mooi ingericht B&B met een zwembad, een prachtig uitzicht dat door twee hele lieve mensen wordt gerund die uit Rotterdam komen en alle restaurantjes en mooie fietsroutes kennen.
                    </p>
                    <p className="mt-4 text-lg text-gray-500">De prijs is verder inclusief vluchten, shuttle service van- en naar het vliegveld, 3 keer ontbijt, guiding, én huurfiets (BMC, Basso of vergelijkbaar). Wat je dus zelf kwijt bent: af en toe een taartje, koffietje, een ijsje, 3 keer avondeten (dat is in Girona niet duur, maar dat kan wel, als je dat zou willen).
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="./kostdat.jpg"
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Moet ik superfit zijn?
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                    Nee. Als je een COPPIRIde kunt rijden, kun je mee. We gaan wel eens over de 100 kilometer, en er zijn natuurlijk ook klimmetjes en afdalingen. Dus ongetraind kun je nou ook weer niet mee. Is iedereen superfit of gaat het juist te hard? Dan passen we het niveau aan. Maar als je met een COPPIRide mee kan, kun je mee.
                    </p>
                  </div>
                </div>
                {/* <div className="mt-6">
                  <a
                    href="mailto:yoram@coppikoffie.nl?subject=Ja, vertel me meer over de Girona-reis!"
                    className="inline-flex bg-yellow-500 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                  >
                    Ja, ik ga mee!
                  </a>
                </div> */}
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="./moetiksuperfitzijn.jpg"
                    alt="COPPI Girona"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative pt-16 pb-32 overflow-hidden" id="featured">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.2983775220537!2d2.8344475157476534!3d41.99387157921315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12bae7ea8cf9c1a7%3A0xe1ea7c14a7bc4a20!2sEquipe+Guesthouse!5e0!3m2!1snl!2snl!4v1566371436540!5m2!1snl!2snl"
            width="100%"
            height="500"
            frameborder="0"
            style={{border:0}}
            title="Equipe Girona on the map"
            allowfullscreen
          ></iframe>
        </div>
      </main>

      <footer className="bg-gray-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
          <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
            <div className="flex space-x-6 md:order-2">
              {footerNavigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; 2023 COPPI Koffie.
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
};
